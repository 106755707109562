exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mentoring-js": () => import("./../../../src/pages/mentoring.js" /* webpackChunkName: "component---src-pages-mentoring-js" */),
  "component---src-pages-songs-tsx": () => import("./../../../src/pages/songs.tsx" /* webpackChunkName: "component---src-pages-songs-tsx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-en-dark-side-dark-side-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/en/dark-side/dark-side.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-en-dark-side-dark-side-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-en-display-stand-upgrade-display-stand-upgrade-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/en/display-stand-upgrade/display-stand-upgrade.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-en-display-stand-upgrade-display-stand-upgrade-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-en-hacked-intercom-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/en/hacked-intercom.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-en-hacked-intercom-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-en-led-matrix-led-matrix-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/en/led-matrix/led-matrix.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-en-led-matrix-led-matrix-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-en-load-testing-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/en/load-testing.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-en-load-testing-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-en-my-experience-with-react-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/en/my-experience-with-react.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-en-my-experience-with-react-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-en-porting-js-to-ts-with-chatgpt-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/en/porting-js-to-ts-with-chatgpt.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-en-porting-js-to-ts-with-chatgpt-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pl-dark-side-dark-side-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/pl/dark-side/dark-side.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pl-dark-side-dark-side-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pl-display-stand-upgrade-display-stand-upgrade-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/pl/display-stand-upgrade/display-stand-upgrade.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pl-display-stand-upgrade-display-stand-upgrade-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pl-hacked-intercom-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/pl/hacked-intercom.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pl-hacked-intercom-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pl-led-matrix-led-matrix-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/pl/led-matrix/led-matrix.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pl-led-matrix-led-matrix-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pl-load-testing-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/pl/load-testing.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pl-load-testing-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pl-my-experience-with-react-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/pl/my-experience-with-react.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pl-my-experience-with-react-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pl-porting-js-to-ts-with-chatgpt-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/pl/porting-js-to-ts-with-chatgpt.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pl-porting-js-to-ts-with-chatgpt-mdx" */),
  "component---src-templates-course-js-content-file-path-src-courses-en-advanced-web-testing-tools-mdx": () => import("./../../../src/templates/course.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/courses/en/advanced-web-testing-tools.mdx" /* webpackChunkName: "component---src-templates-course-js-content-file-path-src-courses-en-advanced-web-testing-tools-mdx" */),
  "component---src-templates-course-js-content-file-path-src-courses-en-backed-javascript-mdx": () => import("./../../../src/templates/course.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/courses/en/backed-javascript.mdx" /* webpackChunkName: "component---src-templates-course-js-content-file-path-src-courses-en-backed-javascript-mdx" */),
  "component---src-templates-course-js-content-file-path-src-courses-en-selected-network-aspects-mdx": () => import("./../../../src/templates/course.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/courses/en/selected-network-aspects.mdx" /* webpackChunkName: "component---src-templates-course-js-content-file-path-src-courses-en-selected-network-aspects-mdx" */),
  "component---src-templates-course-js-content-file-path-src-courses-pl-advanced-web-testing-tools-mdx": () => import("./../../../src/templates/course.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/courses/pl/advanced-web-testing-tools.mdx" /* webpackChunkName: "component---src-templates-course-js-content-file-path-src-courses-pl-advanced-web-testing-tools-mdx" */),
  "component---src-templates-course-js-content-file-path-src-courses-pl-backend-javascript-mdx": () => import("./../../../src/templates/course.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/courses/pl/backend-javascript.mdx" /* webpackChunkName: "component---src-templates-course-js-content-file-path-src-courses-pl-backend-javascript-mdx" */),
  "component---src-templates-course-js-content-file-path-src-courses-pl-selected-network-aspects-mdx": () => import("./../../../src/templates/course.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/courses/pl/selected-network-aspects.mdx" /* webpackChunkName: "component---src-templates-course-js-content-file-path-src-courses-pl-selected-network-aspects-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-arahja-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/arahja.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-arahja-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-barka-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/barka.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-barka-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-dzieci-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/dzieci.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-dzieci-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-gdy-nie-ma-dzieci-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/gdy nie ma dzieci.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-gdy-nie-ma-dzieci-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-here-comes-the-sun-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/here comes the sun.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-here-comes-the-sun-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-hiszpanskie-dziewczyny-md": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/hiszpanskie dziewczyny.md" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-hiszpanskie-dziewczyny-md" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-lewe-lewe-loff-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/lewe lewe loff.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-lewe-lewe-loff-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-morskie-opowiesci-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/morskie opowiesci.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-morskie-opowiesci-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-nazywali-go-marynarz-md": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/nazywali go marynarz.md" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-nazywali-go-marynarz-md" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-nie-zabieraj-mi-strun-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/nie zabieraj mi strun.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-nie-zabieraj-mi-strun-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-pilem-w-spale-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/pilem w spale.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-pilem-w-spale-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-plonie-ognisko-w-lesie-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/plonie ognisko w lesie.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-plonie-ognisko-w-lesie-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-rzuc-jakies-drobne-na-wino-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/rzuc jakies drobne na wino.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-rzuc-jakies-drobne-na-wino-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-trzy-akordy-darcie-mordy-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/trzy akordy darcie mordy.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-trzy-akordy-darcie-mordy-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-wish-you-were-here-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/wish you were here.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-wish-you-were-here-mdx" */),
  "component---src-templates-song-js-content-file-path-src-songs-pl-za-daleko-mdx": () => import("./../../../src/templates/song.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/songs/pl/za daleko.mdx" /* webpackChunkName: "component---src-templates-song-js-content-file-path-src-songs-pl-za-daleko-mdx" */)
}

